import React, { Fragment, useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import color from '../../config/color';
import { oddController } from '../../controllers/oddsController/oddController';
import moment from 'moment';
import Form from 'react-bootstrap/Form';

const PurchaseBettingModal = (props) => {
    const { editData, userId, eventList, seteditData, getVoucherList, t } = props;
    const [purchaseInfo, setPurchaseInfo] = useState({
        "isHome": false,
        "isAway": false,
        "isOver": false,
        "isUnder": false,
        "amount": 0,
        "remark": ""
    });
    const [unit, setUnit] = useState(0);
    const [goal, setGoal] = useState(0);
    const [selectedEvent, setSelectedEvent] = useState(eventList[0]);
    const [betType, setBetType] = useState(null);
    const [oddType, setOddType] = useState(null);
    const [percent, setpercent] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);
    const [errorText,setErrorText] = useState(null);

    useEffect(() => {
        if (editData !== null) {
            const temp_event = {
                homeTeam: editData.isHome || editData.isOver || editData.isUnder ? editData.footballTeam : editData.oppositeTeam,
                awayTeam: editData.isAway ? editData.footballTeam : editData.oppositeTeam,
                homeTeamId: editData.isHome || editData.isOver || editData.isUnder ? editData.footballTeamId : editData.oppositeTeamId,
                awayTeamId: editData.isAway ? editData.footballTeamId : editData.oppositeTeamId,
            }
            setSelectedEvent(temp_event);
            const temp = extractFormattedOdds(editData.isHome || editData.isAway ? editData.bodyOdds : editData.goalOdds);
            setGoal(temp.tempGoal);
            setUnit(temp.tempUnit);
            const newPurchaseInfo = { ...purchaseInfo };
            newPurchaseInfo["isHome"] = editData.isHome;
            newPurchaseInfo["isAway"] = editData.isAway;
            newPurchaseInfo["isOver"] = editData.isOver;
            newPurchaseInfo["isUnder"] = editData.isUnder;
            newPurchaseInfo["remark"] = editData.remark;
            newPurchaseInfo["amount"] = editData.amount;

            setPurchaseInfo(newPurchaseInfo);
            setpercent(editData.isFivePercent === true ? "five" : "eight");
            if (editData.oddsType === "Goal") setBetType(editData.oddsType);
            else {
                if (editData.oddsType === "Over") setOddType(editData.oddsType === "Over");
                if (editData.oddsType === "Under") setOddType(editData.oddsType === "Under");
                setBetType(editData.oddsType);
            }
        } else {
            setSelectedEvent(eventList[0]);
        }

    }, [eventList, editData])

    const handleChangeGoal = (value) => {
        if (value <= 12) {
            setGoal(value);
        }
    };

    const handleClickUnit = (type) => {
        if (type == "inc") {
            setUnit(prev => prev + 5)
        } else {
            setUnit(prev => prev - 5)
        }
    }

    const OnChangeText = (text, value) => {
        const newPurchaseInfo = { ...purchaseInfo };
        if (text === "isHome") {
            newPurchaseInfo["isHome"] = value;
            newPurchaseInfo["isAway"] = false;
            newPurchaseInfo["isOver"] = false;
            newPurchaseInfo["isUnder"] = false;
        }
        else if (text === "isAway") {
            newPurchaseInfo["isHome"] = false;
            newPurchaseInfo["isAway"] = value;
            newPurchaseInfo["isOver"] = false;
            newPurchaseInfo["isUnder"] = false;
        }
        else if (text === "isOver") {
            newPurchaseInfo["isHome"] = false;
            newPurchaseInfo["isAway"] = false;
            newPurchaseInfo["isOver"] = value;
            newPurchaseInfo["isUnder"] = false;
        }
        else if (text === "isUnder") {
            newPurchaseInfo["isHome"] = false;
            newPurchaseInfo["isAway"] = false;
            newPurchaseInfo["isOver"] = false;
            newPurchaseInfo["isUnder"] = value;
        } else {
            newPurchaseInfo[text] = value;
        }

        setPurchaseInfo(newPurchaseInfo);
    };

    const handleRadioSelect = (value) => {
        console.log('dfdfdfd',oddType)
        if (betType !== value) {
            const newPurchaseInfo = { ...purchaseInfo };
            newPurchaseInfo["isHome"] = false;
            newPurchaseInfo["isAway"] = false;
            newPurchaseInfo["isOver"] = false;
            newPurchaseInfo["isUnder"] = false;
            setPurchaseInfo(newPurchaseInfo);
            setGoal(0);
            setUnit(0);
        }
        setBetType(value);
    }

    const handleCancel = () => {
        const newPurchaseInfo = { ...purchaseInfo };
        newPurchaseInfo["isHome"] = false;
        newPurchaseInfo["isAway"] = false;
        newPurchaseInfo["isOver"] = false;
        newPurchaseInfo["isUnder"] = false;
        newPurchaseInfo["remark"] = "";
        newPurchaseInfo["amount"] = 0;
        setGoal(0);
        setUnit(0);
        seteditData(null);
        setSelectedEvent(eventList[0]);
        setBetType(null);
        setpercent(null);
        setErrorText(null);

        setPurchaseInfo(newPurchaseInfo);
    }

    const handleSave = () => {
        setErrorText(null);
        const { amount, isHome, isAway, isOver, isUnder } = purchaseInfo;
        const hasError = validation();
        if(hasError) return;
        setSaveLoading(true);
        const payload = {
            rapidEventId: selectedEvent.rapidEventId,
            footballTeamId: isHome ? selectedEvent.homeTeamId : isAway ? selectedEvent.awayTeamId : selectedEvent.homeTeamId,
            oppositeTeamId: isHome ? selectedEvent.awayTeamId : isAway ? selectedEvent.homeTeamId : selectedEvent.awayTeamId,
            userId: parseInt(userId),
            amount: parseFloat(amount),
            isHome: isHome,
            isAway: isAway,
            isOver: isOver,
            isUnder: isUnder,
            bodyOdds: isHome || isAway ? formattedOdds(goal, unit) : null,
            goalOdds: isOver || isUnder ? formattedOdds(goal, unit) : null,
            selectedDate: selectedEvent.selectedDate,
            remark: purchaseInfo.remark !== "" ? purchaseInfo.remark : null,
            oddsType: betType === "Body" ? oddType : betType,
            isFivePercent: percent === "five" ? true : false
        }

        oddController.createPurchasedVoucher(payload, (data) => {
            setSaveLoading(false);
            getVoucherList();
        });

    }

    const handleUpdate = () => {
        setErrorText(null);
        const { amount, isHome, isAway, isOver, isUnder } = purchaseInfo;
        const hasError = validation();
        if(hasError) return;
        setSaveLoading(true);
        const payload = {
            bettingPurchaseId: editData.bettingPurchaseId,
            rapidEventId: selectedEvent.rapidEventId,
            footballTeamId: isHome ? selectedEvent.homeTeamId : isAway ? selectedEvent.awayTeamId : selectedEvent.homeTeamId,
            oppositeTeamId: isHome ? selectedEvent.awayTeamId : isAway ? selectedEvent.homeTeamId : selectedEvent.awayTeamId,
            amount: parseFloat(amount),
            isHome: isHome,
            isAway: isAway,
            isOver: isOver,
            isUnder: isUnder,
            bodyOdds: isHome || isAway ? formattedOdds(goal, unit) : null,
            goalOdds: isOver || isUnder ? formattedOdds(goal, unit) : null,
            remark: purchaseInfo.remark !== "" ? purchaseInfo.remark : null,
            oddsType: betType === "Body" ? oddType : betType,
            isFivePercent: percent === "five" ? true : false
        }

        oddController.updatePurchasedVoucher(payload, (data) => {
            setSaveLoading(false);
            getVoucherList();
        });
    }

    const validation = () => {
        const { amount, isHome, isAway, isOver, isUnder } = purchaseInfo;
        if(percent === null){
            setErrorText(t('commErr'));
            return true;
        }
        if(betType === null){
            setErrorText(t('betTypeErr'));
            return true;
        }
        if(isHome === false && isAway === false && isOver === false && isUnder === false){
            setErrorText(t('betErr'));
            return true;
        }
        if(betType === "Body" && oddType === null){
            setErrorText(t('oddTypeErr'));
            return true;
        }
        if(amount === 0){
            setErrorText(t('betAmtErr'));
            return true;
        }
    }

    return (
            <div
                className="modal fade"
                id="purchaseBettingModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="customeraddModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" id="customeraddModalLabel">
                                <h5>{editData === null ? t('addNew') : t('edit')}</h5>
                            </div>

                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => handleCancel()}
                            ></button>
                        </div>
                        <div className="modal-body" style={{ fontSize: '0.8rem' }}>
                            <div className="mb-1">
                                <label htmlFor="exampleInputEmail1" className="form-label fw-bold">
                                    {t("event")} <span className="text-danger">*</span>
                                </label>
                                <div style={{ width: '100%',marginLeft:5 }}>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            id="dropdown-basic"
                                            disabled={editData !== null}
                                            style={{
                                                width: '100%',
                                                backgroundColor: 'white',
                                                color: '#000',
                                                borderColor: 'gray',
                                                marginLeft: 5,
                                                fontSize: '0.87rem',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}>
                                            {eventList.length > 0 ? `${selectedEvent?.homeTeam} vs ${selectedEvent?.awayTeam}` : 'No Event'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ fontSize: '0.87rem', width: '100%', background: '#f6f4f1' }}>
                                            {
                                                eventList && eventList.map((v, i) => {
                                                    return (
                                                        <Dropdown.Item onClick={() => setSelectedEvent(v)} key={i}>{v.homeTeam} vs {v.awayTeam}</Dropdown.Item>
                                                    )
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="mb-2 d-flex justify-content-between align-items-center" style={{marginLeft:5}}>
                                <div className=''>
                                    <input
                                        type="checkbox"
                                        style={{ fontSize: '0.8rem', marginRight: 5 }}
                                        checked={percent === "five"}
                                        onChange={(v) => setpercent("five")}
                                        className="form-check-input"
                                        id="five"
                                    />
                                    <label className="form-check-label" htmlFor="five">
                                        5 % {t('commission')}
                                    </label>
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        style={{ fontSize: '0.8rem', marginRight: 5 }}
                                        checked={percent === "eight"}
                                        onChange={(v) => setpercent("eight")}
                                        className="form-check-input"
                                        id="eight"
                                    />
                                    <label className="form-check-label" htmlFor="eight">
                                        8 % {t('commission')}
                                    </label>
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="exampleInputEmail1" className="form-label fw-bold">
                                    {t('betType')} <span className="text-danger">*</span>
                                </label>
                                <div key={`inline-radio`} className="form-check">
                                    <Form.Check
                                        inline
                                        style={{ cursor: 'pointer' }}
                                        value={"body"}
                                        checked={betType === "Body"}
                                        label={t("body")}
                                        name="body"
                                        type="radio"
                                        id={`inline-radio-body`}
                                        onChange={() => handleRadioSelect("Body")}
                                    />
                                    <Form.Check
                                        inline
                                        style={{ cursor: 'pointer' }}
                                        value={"goal"}
                                        checked={betType === "Goal"}
                                        label={t("totalGoal")}
                                        name="goal"
                                        type="radio"
                                        id={`inline-radio-goal`}
                                        onChange={() => handleRadioSelect("Goal")}
                                    />
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="remark" className="form-label fw-bold">
                                    {t('betChoice')} <span className="text-danger">*</span>
                                </label>
                                <div className='form-check d-flex' style={{ gap: 30,marginLeft:15 }}>
                                    {
                                        betType === "Body" ? <Fragment>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    style={{ fontSize: '0.8rem' }}
                                                    checked={purchaseInfo.isHome}
                                                    onChange={(v) => OnChangeText("isHome", v.target.checked)}
                                                    className="form-check-input"
                                                    id="home"
                                                />
                                                <label className="form-check-label" htmlFor="home">
                                                    {t('home')}
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    style={{ fontSize: '0.8rem' }}
                                                    checked={purchaseInfo.isAway}
                                                    onChange={(v) => OnChangeText("isAway", v.target.checked)}
                                                    className="form-check-input"
                                                    id="away"
                                                />
                                                <label className="form-check-label" htmlFor="away">
                                                    {t('away')}
                                                </label>
                                            </div>
                                        </Fragment>
                                            : <Fragment>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        style={{ fontSize: '0.8rem' }}
                                                        checked={purchaseInfo.isOver}
                                                        onChange={(v) => OnChangeText("isOver", v.target.checked)}
                                                        className="form-check-input"
                                                        id="over"
                                                    />
                                                    <label className="form-check-label" htmlFor="over">
                                                        {t('over')}
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        style={{ fontSize: '0.8rem' }}
                                                        checked={purchaseInfo.isUnder}
                                                        onChange={(v) => OnChangeText("isUnder", v.target.checked)}
                                                        className="form-check-input"
                                                        id="under"
                                                    />
                                                    <label className="form-check-label" htmlFor="under">
                                                        {t('under')}
                                                    </label>
                                                </div>
                                            </Fragment>
                                    }
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="exampleInputPassword1" className="form-label fw-bold">
                                    {t('odds')} <span className="text-danger">*</span>
                                </label>
                                <div className='d-flex' style={{marginLeft:15}}>
                                    <input type="number"
                                        value={goal}
                                        onChange={(e) => handleChangeGoal(e.target.value)}
                                        className="custom-input"
                                        style={{ width: 65, fontSize: '0.875rem', marginRight: 20, height: 35 }}
                                        min={0}
                                        max={12}
                                    />
                                    <div style={{ display: 'flex' }}>
                                        <button
                                            style={{ borderRadius: 5, backgroundColor: "#f7f7f7", borderWidth: 1, marginRight: 20 }}
                                            onClick={() => handleClickUnit("inc")}
                                        ><i className="fas fa-arrow-up"></i>
                                        </button>
                                        <input
                                            type="number"
                                            value={unit}
                                            className="form-control"
                                            style={{ width: 70, fontSize: '0.875rem', marginRight: 20 }}
                                            disabled
                                        />
                                        <button
                                            style={{ borderRadius: 5, backgroundColor: "#f7f7f7", borderWidth: 1 }}
                                            onClick={() => handleClickUnit("dec")}
                                        >
                                            <i className="fas fa-arrow-down"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {betType === "Body" ?
                                <div className="mb-2">
                                    <label htmlFor="exampleInputEmail1" className="form-label fw-bold">
                                        {t('oddType')} <span className="text-danger">*</span>
                                    </label>
                                    <div key={`inline-radio`} className="form-check">
                                        <Form.Check
                                            inline
                                            value="Over"
                                            label={t('overOdds')}
                                            checked={oddType === "Over"}
                                            name="over-odds"
                                            type="radio"
                                            id={`inline-radio-over`}
                                            onChange={() => setOddType("Over")}
                                        />
                                        <Form.Check
                                            inline
                                            value="Under"
                                            label={t('underOdds')}
                                            name="under-odds"
                                            checked={oddType === "Under"}
                                            type="radio"
                                            id={`inline-radio-under`}
                                            onChange={() => setOddType("Under")}
                                        />
                                    </div>
                                </div> : null}
                            <div className="mb-2 row">
                                <div className='col-6'>
                                    <label htmlFor="betAmount" className="form-label fw-bold">
                                        {t('betAmt')} <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        value={purchaseInfo.amount}
                                        className="custom-input"
                                        id="betAmount"
                                        style={{ fontSize: '0.8rem', height: 35, marginLeft:15}}
                                        required
                                        aria-describedby="emailHelp"
                                        onChange={(value) =>
                                            OnChangeText("amount", value.target.value)
                                        }
                                    />
                                </div>
                                <div className='col-6'>
                                    <label htmlFor="remark" className="form-label fw-bold">
                                        {t('remark')} *
                                    </label>
                                    <input
                                        type="text"
                                        value={purchaseInfo.remark}
                                        className="custom-input"
                                        step="any"
                                        style={{ fontSize: '0.8rem', height: 35 }}
                                        onChange={(value) =>
                                            OnChangeText("remark", value.target.value)
                                        }
                                        id="remark"
                                    />
                                </div>
                            </div>

                            <div className="d-flex justify-content-between">
                                <span className='text-danger'>{errorText}</span>
                                <div>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ fontSize: '0.8rem' }}
                                    onClick={() => handleCancel()}
                                >
                                    {t('close')}
                                </button>
                                {
                                    editData === null ?
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            disabled={eventList.length === 0}
                                            onClick={() => handleSave()}
                                            style={{ marginLeft: 5, fontSize: '0.8rem', backgroundColor: color['dark'].main, color: '#fff' }}
                                        >
                                            {saveLoading ? "Loading ... " : t('save')}
                                        </button>
                                        : <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => handleUpdate()}
                                            style={{ marginLeft: 5, fontSize: '0.8rem', backgroundColor: color['dark'].main, color: '#fff' }}
                                        >
                                            {saveLoading ? "Loading ... " : t('update')}
                                        </button>
                                }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    )
}

export default PurchaseBettingModal;

const formattedOdds = (goal, unit) => {
    const tempGoal = goal == 0 ? '=' : goal.toString();
    const tempUnit = goal > 0 ? unit == 0 ? '+0' : unit > 0 ? '+' + unit.toString() : unit.toString() : goal == 0 && unit == 0 ? 'D' : unit.toString();
    const oddsUpdated = tempGoal + tempUnit;

    return oddsUpdated;
}

const extractFormattedOdds = (odds) => {
    const isExist = odds.includes("=");
    let tempGoal = 0;
    let tempUnit = 0;

    if (isExist) {
        const arr = odds.split(/[=]/);
        tempGoal = 0;
        tempUnit = arr[1] == 'D' ? 0 : parseInt(arr[1]);
    }
    else {
        const isExist_plus = odds.includes("+");

        if (isExist_plus) {
            const arr = odds.split(/[+]/);
            tempGoal = arr[0];
            tempUnit = parseInt(arr[1]);
        }
        else {
            const arr = odds.split(/[-]/);
            tempGoal = arr[0];
            tempUnit = -1 * parseInt(arr[1]);
        }
    }

    return { tempGoal, tempUnit };
}