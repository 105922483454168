import React, { useEffect, useState } from 'react';
import NavBar from './components/NavBar';
import { useHistory } from "react-router-dom";
import Loading from './components/Loading';
import { useTranslation } from "react-i18next";
import color from '../config/color';
import PurchaseBettingModal from './components/PurchaseBettingModal';
import { oddController } from '../controllers/oddsController/oddController';
import moment from 'moment/moment';
import ReactPaginate from "react-paginate";

const BettingPurchase = () => {
    const { t,i18n } = useTranslation("global");
    const [username, setUsername] = useState("");
    const [userRole, setUserRole] = useState();
    const [page, setPage] = useState(0);
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const rowsPerPage = 7;
    const [pageCount, setPageCount] = useState(0);
    const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [voucherList, setVoucherList] = useState([]);
    const userId = localStorage.getItem("userId");
    const [eventList,setEventList] = useState([]);
    const [editData,seteditData] = useState(null);

    useEffect(() => {
        const userName = localStorage.getItem("userName");
        const userRole = localStorage.getItem("userRole");

        if (userName == undefined || userRole > 2 || userName !== "paingpaing") {
            history.push("/");
        }

        setUsername(userName);
        setUserRole(userRole);
        getVoucherList();
        getEventList();
    }, []);

    const getVoucherList = () => {
        setLoading(true);
        oddController.getPurchasedVouchers(date, parseInt(userId), (data) => {
            setVoucherList(data.payload);
            setPage(Math.ceil(data.payload.length / rowsPerPage));
            setLoading(false);
        });
    }

    const getEventList = () => {
        oddController.getEventListForSelect(parseInt(userId), (data) => {
            setEventList(data.payload);
        });
    }

    const handleSearch = () => {
        getVoucherList();
      };

      const handlePageClick = async (data) => {
        setPageCount(data.selected);
      };

    return (
        <div>
            <PurchaseBettingModal t={t} userId={userId} editData={editData} eventList={eventList} seteditData={seteditData} getVoucherList={getVoucherList}/>
            <NavBar username={username} purchasecolor={"link-btn-active"} userRole={userRole} />
            {isLoading ? (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Loading />
                    <p>Loading .....</p>
                </div>
            ) : (
                <div>
                    <span className="site-header" style={{ color: color['dark'].main }}>{t('purchase')}</span>
                        <div className="customer-header">
                            <div className="d-flex">
                            <div className="bd-highlight">
                                <div className="mb-2">
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="birthday"
                                        value={date}
                                        style={{ fontSize: '0.8rem' }}
                                        onChange={(e) => setDate(e.target.value)}
                                        name="goal-calculate"
                                    />
                                </div>
                            </div>
                            <div className="bd-highlight">
                                <button
                                    type="button"
                                    className="btn"
                                    style={{ backgroundColor: color['dark'].main, color: '#fff', fontSize: '0.8rem', marginLeft: 5 }}
                                    onClick={() => handleSearch()}
                                >
                                    {t('search')}
                                </button>
                            </div>
                            </div>
                            <button
                                type="button"
                                className="btn"
                                disabled={eventList.length === 0}
                                style={{ backgroundColor: color['dark'].main, color: '#fff', fontSize: '0.8rem' }}
                                data-bs-target="#purchaseBettingModal"
                                data-bs-toggle="modal"
                            >
                                <i className="fas fa-add my-icon"></i>&nbsp;{t('addNew')}
                            </button> 
                        </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead style={{ fontSize: '0.755rem', backgroundColor: color['dark'].headerbg }}>
                                <tr>
                                    <th scope="col">{t('no')}</th>
                                    <th scope="col">{t('bettedDate')}</th>
                                    <th scope="col">{t('choice')}</th>
                                    <th scope="col">{t('odds')}</th>
                                    <th scope="col">{t('betAmt')}</th>
                                    <th scope="col">{t('wlAmount')}</th>
                                    <th scope="col">{t('commission')}</th>
                                    <th scope="col">{t('totalAmount')}</th>
                                   
                                    <th scope="col">{t('status')}</th>
                                    <th scope="col">{t('action')}</th>
                                    <th scope="col">{t('remark')}</th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: '0.75rem' }}>
                                {voucherList.length != 0 ?
                                    voucherList &&
                                    voucherList
                                        .slice(
                                            pageCount * rowsPerPage,
                                            pageCount * rowsPerPage + rowsPerPage
                                        )
                                        .map((d, i) => {
                                            return (
                                                <tr key={d.bettingPurchaseId}>
                                                    <th scope="row">{pageCount * rowsPerPage + i + 1}</th>
                                                    <td>
                                                        <div style={{display:'flex',flexDirection:'column'}}> 
                                                            <span>{moment(d.createdAt).format("YYYY-MM-DD")}</span>
                                                            <span>{moment(d.createdAt).format("hh:mm a")}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {d.isOver ?
                                                            <span style={{color:'red'}}>{i18n.language === "mm" ? d.footballTeamMyan :d.footballTeam}({t('over')})</span>
                                                            : d.isUnder ?
                                                                <span style={{color:'green'}}>{i18n.language === "mm" ? d.footballTeamMyan :d.footballTeam}({t('under')})</span>
                                                                : <span style={{color : d.oddsType === "Over" ? 'red' : 'green'}}>{i18n.language === "mm" ? d.footballTeamMyan :d.footballTeam}</span>}
                                                    </td>
                                                    <td>{d.isHome || d.isAway ? d.bodyOdds : d.goalOdds}</td>
                                                    <td>{d.amount}</td>
                                                    <td><span style={{color:d.winLoseAmount < 0 ? 'red' : 'black'}}>{d.winLoseAmount}</span></td>
                                                    <td>{d.commission}</td>
                                                    <td><span style={{color:d.winLoseAmount < 0 ? 'red' : 'black'}}>{d.winLoseAmount - d.commission}</span></td>
                    
                                                    <td>{d.status === "pending" ?
                                                        <span className='badge bg-warning text-dark' style={{width:50}}>{d.status}</span> :
                                                        d.status === "Win" ?
                                                        <span className='badge bg-success' style={{width:50}}>{d.status}</span> :
                                                        <span className='badge bg-danger' style={{width:50}}>{d.status}</span>
                                                    }
                                                    </td>
                                                    <td>
                                                        { d.status === "pending" ?
                                                        <button
                                                            className="btn"
                                                            style={{ 
                                                                backgroundColor: color['dark'].main, 
                                                                color: '#fff', 
                                                                fontSize: '0.75rem' 
                                                            }}
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#purchaseBettingModal"
                                                            onClick={() =>
                                                                seteditData(d)
                                                            }
                                                        >
                                                            <i className="fas fa-edit my-icon"></i>&nbsp;{t('edit')}
                                                        </button> : null
                                        }
                                                        <button
                                                            className="btn"
                                                            style={{ 
                                                                backgroundColor: color['dark'].secondary6, 
                                                                color: '#fff', 
                                                                fontSize: '0.75rem',
                                                                marginLeft:3
                                                             }}
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#purchaseBettingModal"
                                                            // onClick={() =>
                                                            //     seteditData(d)
                                                            // }
                                                        >
                                                            <i className="fas fa-trash my-icon"></i>&nbsp;{t('delete')}
                                                        </button>
                                                    </td>
                                                    <td>{d.remark}</td>
                                                </tr>
                                            );
                                        })
                                    :
                                    <tr>
                                        <td colSpan={11} style={{ textAlign: 'center' }}>{t('nodata')}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
            previousLabel={t('previous')}
            nextLabel={t('next')}
            breakLabel={"..."}
            pageCount={page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
                </div>
            )
            }
        </div>
    )
}

export default BettingPurchase;

const data = [
    {
        "bettingPurchaseId": 1,
        "rapidEventId": 111111,
        "homeTeam": "Man U",
        "awayTeam": "Chelsea",
        "createdAt": "2024-10-01 15:00:00",
        "isHome": true,
        "isAway": false,
        "isOver": true,
        "isUnder": false,
        "remark": "dddddddd",
        "bodyOdds": "3+90",
        "goalOdds": "2+90",
        "amount": 40000,
        "status": "Win",
        "winLoseAmount": 0,
        "validAmount": 0,
        "commission": 0
    },
    {
        "bettingPurchaseId": 1,
        "rapidEventId": 111111,
        "homeTeam": "Man U",
        "awayTeam": "Chelsea",
        "createdAt": "2024-10-01 15:00:00",
        "isHome": true,
        "isAway": false,
        "isOver": true,
        "isUnder": false,
        "remark": "dddddddd",
        "bodyOdds": "3+90",
        "goalOdds": "2+90",
        "amount": 40000,
        "status": "Win",
        "winLoseAmount": 0,
        "validAmount": 0,
        "commission": 0
    },
    {
        "bettingPurchaseId": 1,
        "rapidEventId": 111111,
        "homeTeam": "Man U",
        "awayTeam": "Chelsea",
        "createdAt": "2024-10-01 15:00:00",
        "isHome": true,
        "isAway": false,
        "isOver": true,
        "isUnder": false,
        "remark": "dddddddd",
        "bodyOdds": "3+90",
        "goalOdds": "2+90",
        "amount": 40000,
        "status": "Win",
        "winLoseAmount": 0,
        "validAmount": 0,
        "commission": 0
    },
    {
        "bettingPurchaseId": 1,
        "rapidEventId": 111111,
        "homeTeam": "Man U",
        "awayTeam": "Chelsea",
        "createdAt": "2024-10-01 15:00:00",
        "isHome": true,
        "isAway": false,
        "isOver": true,
        "isUnder": false,
        "remark": "dddddddd",
        "bodyOdds": "3+90",
        "goalOdds": "2+90",
        "amount": 40000,
        "status": "Win",
        "winLoseAmount": 0,
        "validAmount": 0,
        "commission": 0
    }
]